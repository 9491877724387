import includes from 'lodash/includes'
import without from 'lodash/without'
import findIndex from 'lodash/findIndex'
import reject from 'lodash/reject'
import difference from 'lodash/difference'
import sumBy from 'lodash/sumBy'

import { getObjProperty } from './objects'

export const addOrRemoveFromArray = (array, value) => {
  if (Array.isArray(value)) {
    const newValues = difference(value, array)
    return newValues.length === 0
      ? difference(array, value)
      : array.concat(newValues)
  }

  return includes(array, value) ? without(array, value) : array.concat(value)
}

export const removeObjectFromArray = (array, object, key) => {
  return array.filter(item => item[key] !== object[key])
}

export const addOrRemoveObjectFromArray = (array, obj, key) => {
  const newArray = removeObjectFromArray(array, obj, key)
  if (newArray.length < array.length) {
    return newArray
  }
  return [...array, obj]
}

export const sortAsc = column => (a, b) => {
  const aValue = getObjProperty(a, column)
  const bValue = getObjProperty(b, column)

  if (aValue && bValue) {
    if (aValue < bValue) {
      return -1
    }

    if (aValue > bValue) {
      return 1
    }
  } else if (aValue && !bValue) {
    return 1
  } else if (!aValue && bValue) {
    return -1
  }

  return 0
}

export const sortDesc = column => (a, b) => {
  return sortAsc(column)(a, b) * -1
}

export const sortBy = (column, direction = 'asc') => {
  const sortFunction = {
    asc: sortAsc,
    desc: sortDesc
  }[direction]

  return sortFunction(column)
}

export const replaceAtIndex = (array, index, item) => {
  const copy = [...array]

  if (index === -1 || index >= copy.length) {
    return copy
  }

  copy[index] = item

  return copy
}

export const removeFromArray = (array, values) => {
  return reject(array, value => includes(values, value))
}

/**
 *
 * For updating an object in an array
 *
 */
export const updateInArray = (array, key, newProperties) => {
  const i = findIndex(array, key)

  if (i === -1) {
    return array
  }

  const newValue = {
    ...array[i],
    ...newProperties
  }
  return Object.assign(array.slice(), {
    [i]: newValue
  })
}

export const updateOrAddObjectToArray = (array, object, key) => {
  if (Object.prototype.toString.call(object) !== '[object Object]') {
    return null
  }

  const index = array.findIndex(item => item[key] === object[key])
  const updatedArray = array.slice()

  if (index === -1) {
    updatedArray.push(object)
  } else {
    updatedArray[index] = object
  }

  return updatedArray
}

export const updateAllInArray = (array, newProperties) => {
  return array.map(item => {
    return {
      ...item,
      ...newProperties
    }
  })
}

export const updateSelectedInArray = (
  array,
  key,
  selectedKeys,
  newProperties
) => {
  return array.map(item => {
    if (selectedKeys.includes(item[key])) {
      return {
        ...item,
        ...newProperties
      }
    }
    return item
  })
}

// Given an array of objects, sum the values based on property names
// EG: Sum this array, but with values from properties 'propA', and 'propB'
// [{propA: 1, propB: 1, propC: 1}] // => 2
export const sumArrayByProperties = (array, properties) => {
  const iterator = (total, property) => total + sumBy(array, property)
  return properties.reduce(iterator, 0)
}

// Function to convert each element in the array to a property in an object
// For example: if you have this array:
//    [{label: 'a', value: 1}, {label: 'b', value: 2}]
// and pass these attributes
//    array=[{label: 'a', value: 1}, {label: 'b', value: 2}]
//    keyProp='label'
//    valueProp='value'
// the data returned will be
//   { obj: { a: 1, b: 2 }, keysAdded: ['label']}
//  Note: the last object is optional, if no object is provided the function will return
//        a new one. If an object is provided the new keys will be added to the existing
//        object
export const convertArrayElementsToObject = (
  array,
  keyProp,
  valueProp,
  obj = {}
) => {
  const keysAdded = []
  if (Array.isArray(array)) {
    array.forEach(element => {
      obj[element[keyProp]] = element[valueProp]
      keysAdded.push(element[keyProp])
    })
  }
  return { obj, keysAdded }
}

export const removeDuplicatedElements = array => {
  return array.filter((item, index) => array.indexOf(item) === index)
}

// Helper that will go through every array received and for each object in them
// will return the value in the propert requested.
// If the keepDuplicated is set to false, the duplicated values will be removed
export const getObjectsPropertyFromArray = (
  arrays,
  property,
  keepDuplicated = false
) => {
  let keys = []
  if (Array.isArray(arrays)) {
    arrays.forEach(array => {
      if (Array.isArray(array) && array.length > 0) {
        keys = [...keys, ...array.map(element => element[property])]
      }
    })
  }
  return keepDuplicated ? keys : removeDuplicatedElements(keys)
}

// Deep clone (1 level only, no nested objects) an array
export const shallowCloneArrayOfObjects = array => {
  return array.map(item => {
    return { ...item }
  })
}

export const groupBy = (array, valueAttr) => {
  return array.reduce((acc, current) => {
    const value = current[valueAttr]
    const existingItemIndex = acc.findIndex(item => item[valueAttr] === value)
    // New element, never seen before
    if (existingItemIndex === -1) {
      acc.push({ [valueAttr]: value, count: 1 })
    } else {
      // Element already exists, just need to increment the count
      acc[existingItemIndex].count += 1
    }
    return acc
  }, [])
}

export const filterArray = (array, keysToInclude) => {
  return array.filter(key => keysToInclude.includes(key))
}

export const filterObjectsArray = (array, key, value) => {
  return array.filter(item => item[key] === value)
}

export const isArrayEmpty = array => {
  if (!Array.isArray(array)) {
    return false
  }
  return array.length === 0
}

export const reSortItems = (items, oldPosition, newPosition, item) => {
  items.splice(oldPosition, 1)
  items.splice(newPosition, 0, item)
  return items
}

export const moveArrayItemToGivenPosition = (array, from, to) => {
  let numberOfDeletedElm = 1
  const elm = array.splice(from, numberOfDeletedElm)[0]
  numberOfDeletedElm = 0
  array.splice(to, numberOfDeletedElm, elm)

  return array
}

export const uniqueArrayObjects = (array = null, uniqueProperty = null) => {
  if (!array || !uniqueProperty) return []

  const identifierValues = array.map(item => item[uniqueProperty])
  const identifierValuesUnique = [...new Set(identifierValues)]

  return identifierValuesUnique.map(identifierValue => {
    return array.find(item => item[uniqueProperty] === identifierValue)
  })
}

export const getSum = array => {
  if (!array || !array.length) {
    return null
  }
  return array.reduce((acc, value) => acc + value, 0)
}

export const getAverage = array => {
  if (!array || !array.length) {
    return null
  }
  return getSum(array) / array.length
}

export const averageByProperty = (array, property) => {
  if (array.length === 0) return null

  const total = array.reduce(
    (accumulated, item) => accumulated + item[property],
    0
  )

  return total / array.length
}

export const trimEmptyEntries = (entries, isEntryEmptyCallback) => {
  const totalEntriesCount = entries.length

  let trimStart = 0
  let trimEnd = totalEntriesCount - 1

  // using for loop here so we can easily check entries from the beginning and the end in one loop
  // instead of iterating multiple times
  for (let i = 0; i < totalEntriesCount; i += 1) {
    // this ensures breaking from the loop as soon as there's nothing to trim anymore
    if (trimStart !== i && trimEnd !== totalEntriesCount - i - 1) {
      break
    }

    // if empty and if previous entry was empty as well
    if (trimStart === i && isEntryEmptyCallback(entries[i])) {
      trimStart = i + 1
    }

    // if empty and if previous entry (from the end - reversed) was empty as well
    if (
      trimEnd === totalEntriesCount - i - 1 &&
      isEntryEmptyCallback(entries[totalEntriesCount - i - 1])
    ) {
      trimEnd = totalEntriesCount - i - 2
    }
  }

  // only slice/trim if trimStart or trimEnd values are changed
  if (trimStart !== 0 || trimEnd !== totalEntriesCount - 1) {
    return entries.slice(trimStart, trimEnd + 1)
  }

  return entries
}
/**
 * Find an item of the ArrayLike Object which is not match the direction
 * For an increasing(asc) array, find the decrement start item
 * For a decrementing(desc) array, find the increment start item
 * @param  {ArrayLike} array An ArrayLike Object
 * @param  {Functoin} getValue Get Value of each item
 * @param  {asc|desc} direction The order of the array
 */
export const findReverseElements = (
  array,
  getValue = v => v,
  direction = 'asc'
) => {
  const reverseElements = []
  if (array.length > 1)
    array.reduce((pre, cur) => {
      const preValue = getValue(pre)
      const curValue = getValue(cur)
      if (
        (direction === 'asc' && preValue > curValue) ||
        (direction === 'desc' && preValue < curValue)
      ) {
        reverseElements.push(direction === 'asc' ? pre : cur)
      }
      return cur
    })
  return reverseElements
}

/**
 *
 * Adds a new item to the array
 * If the item is already in the array, it will not be added again, instead it will be moved to the beginning of the array
 * If the item is not in the array, it will be added to the beginning of the array
 * If array has more than the limit number of items, the last item will be removed
 *
 * @param {Array} array An array of primitive elements
 * @param {Primitive} item The item to add to the array
 * @param {Number} limit The limit of the array
 *
 * @returns {Array} The array with the item added
 */
export const addUniqueItemToLimitedArray = (array, item, limit = 5) => {
  // check if item is already in the array and if it is remove it as we will add it at the beginning of the array
  const index = array.findIndex(el => el === item)
  if (index !== -1) {
    array.splice(index, 1)
  }

  if (array.length >= limit) {
    array.pop()
  }

  array.unshift(item)

  return array
}

export const sortByPosition = array =>
  array.sort((a, b) => {
    if (a.position > b.position) return 1
    if (b.position > a.position) return -1

    return 0
  })

export const mergeArraysOfObjectsByKey = (array1, array2, key) => {
  if (!key) {
    return [...array1, ...array2]
  }
  if (!Array.isArray(array1) && Array.isArray(array2)) {
    return array2
  }
  if (Array.isArray(array1) && !Array.isArray(array2)) {
    return array1
  }

  const ids = new Set(array1.map(d => d[key]))
  return [...array1, ...array2.filter(d => !ids.has(d[key]))]
}

export const isArraysEqual = (array1, array2) => {
  if (!array1 || !array2 || array1.length !== array2.length) {
    return false
  }

  // Sort both arrays
  const sortedArr1 = array1.slice().sort()
  const sortedArr2 = array2.slice().sort()

  // Compare sorted arrays
  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)
}
