import React from 'react'
import { Translation } from 'react-i18next'
import {
  endOfDay,
  isSameDay,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  startOfYear,
  endOfYear,
  differenceInDays
} from 'date-fns'

import {
  COMPARISON_TYPES,
  DEFAULT_MIN_DATE,
  TODAY_DATE
} from 'constants/calendar'

// This can't go into the constant file because it needs to be created dynamically
// As a constant the today's date would always be the same when leaving the app open for several days

export const createDateRanges = (baseDate, dataIds) => [
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.yesterday', 'Yesterday')}
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(1, 'day')
      .startOf('day'),
    endDate: baseDate
      .clone()
      .subtract(1, 'day')
      .endOf('day'),
    dataId: dataIds?.Yesterday,
    rangeType: 'Yesterday'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.thisWeek', 'This Week')}
      </Translation>
    ),
    startDate: baseDate.clone().startOf('week'),
    endDate: baseDate.clone(),
    dataId: dataIds?.ThisWeek,
    rangeType: 'This Week'
  },
  {
    name: (
      <Translation ns="common">
        {t =>
          t('common:CalendarSelect.datePicker.lastSevenDays', 'Last 7 Days')
        }
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(6, 'days')
      .startOf('day'),
    endDate: baseDate.clone(),
    dataId: dataIds?.LastSevenDays,
    rangeType: 'Last 7 Days'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.lastWeek', 'Last Week')}
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(1, 'week')
      .startOf('week'),
    endDate: baseDate
      .clone()
      .subtract(1, 'week')
      .endOf('week'),
    dataId: dataIds?.LastWeek,
    rangeType: 'Last Week'
  },
  {
    name: (
      <Translation ns="common">
        {t =>
          t('common:CalendarSelect.datePicker.lastFourteenDays', 'Last 14 Days')
        }
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(13, 'days')
      .startOf('day'),
    endDate: baseDate.clone(),
    dataId: dataIds?.LastFourteenDays,
    rangeType: 'Last 14 Days'
  },
  {
    name: (
      <Translation ns="common">
        {t =>
          t('common:CalendarSelect.datePicker.lastThirtyDays', 'Last 30 Days')
        }
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(29, 'days')
      .startOf('day'),
    endDate: baseDate.clone(),
    dataId: dataIds?.LastThirtyDays,
    rangeType: 'Last 30 Days'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.lastMonth', 'Last Month')}
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(1, 'month')
      .startOf('month'),
    endDate: baseDate
      .clone()
      .subtract(1, 'month')
      .endOf('month'),
    dataId: dataIds?.LastMonth,
    rangeType: 'Last Month'
  },
  {
    name: (
      <Translation ns="common">
        {t =>
          t('common:CalendarSelect.datePicker.lastNinetyDays', 'Last 90 days')
        }
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(89, 'days')
      .startOf('day'),
    endDate: baseDate.clone(),
    dataId: dataIds?.LastNinetyDays,
    rangeType: 'Last 90 Days'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.lastQuarter', 'Last Quarter')}
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(1, 'quarter')
      .startOf('quarter'),
    endDate: baseDate
      .clone()
      .subtract(1, 'quarter')
      .endOf('quarter'),
    dataId: dataIds?.LastQuarter,
    rangeType: 'Last Quarter'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.1year', '1 Year')}
      </Translation>
    ),
    startDate: baseDate.clone().subtract(365, 'days'),
    endDate: baseDate.clone(),
    dataId: dataIds?.OneYear,
    rangeType: '1 Year'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.lastYear', 'Last Year')}
      </Translation>
    ),
    startDate: baseDate
      .clone()
      .subtract(1, 'year')
      .startOf('year'),
    endDate: baseDate
      .clone()
      .subtract(1, 'year')
      .endOf('year'),
    dataId: dataIds?.LastYear,
    rangeType: 'Last Year'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.mtd', 'MTD')}
      </Translation>
    ),
    startDate: baseDate.clone().startOf('month'),
    endDate: baseDate.clone(),
    dataId: dataIds?.MTD,
    rangeType: 'MTD'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.qtd', 'QTD')}
      </Translation>
    ),
    startDate: baseDate.clone().startOf('quarter'),
    endDate: baseDate.clone(),
    dataId: dataIds?.QTD,
    rangeType: 'QTD'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.ytd', 'YTD')}
      </Translation>
    ),
    startDate: baseDate.clone().startOf('year'),
    endDate: baseDate.clone(),
    dataId: dataIds?.YearToDate,
    rangeType: 'YTD'
  },
  {
    name: (
      <Translation ns="common">
        {t => t('common:CalendarSelect.datePicker.2years', '2 Years')}
      </Translation>
    ),
    startDate: baseDate.clone().subtract(2, 'year'),
    endDate: baseDate.clone(),
    dataId: dataIds?.TwoYears,
    rangeType: '2 Years'
  }
]

export const DEFAULT_RANGE_TYPE = '1 Year'

export const calendarDateRangeOptions = (
  baseDate = TODAY_DATE,
  startDate = DEFAULT_MIN_DATE,
  minDate = DEFAULT_MIN_DATE,
  maxDate = TODAY_DATE
) => {
  const endOfToday = endOfDay(baseDate)
  const currentMonthNumber = baseDate.getMonth()

  return {
    allTime: {
      label: (
        <Translation ns="common">
          {t => t('common:CalendarRanges.allTime', 'All Time')}
        </Translation>
      ),
      startDate: startOfDay(minDate),
      endDate: endOfDay(maxDate),
      rangeType: 'allTime'
    },
    today: {
      label: (
        <Translation ns="common">
          {t => t('common:CalendarRanges.today', 'Today')}
        </Translation>
      ),
      startDate: startOfDay(baseDate),
      endDate: endOfToday,
      rangeType: 'today'
    },
    yesterday: {
      label: (
        <Translation ns="common">
          {t => t('common:CalendarRanges.yesterday', 'Yesterday')}
        </Translation>
      ),
      startDate: startOfDay(subDays(baseDate, 1)),
      endDate: endOfDay(subDays(baseDate, 1)),
      rangeType: 'yesterday'
    },
    weekToDate: {
      label: (
        <Translation ns="common">
          {t => t('common:CalendarRanges.wtd', 'WTD')}
        </Translation>
      ),
      startDate: startOfWeek(baseDate),
      endDate: endOfToday,
      rangeType: 'weekToDate'
    },
    lastWeek: {
      label: (
        <Translation ns="generic">
          {t => t('common:CalendarRanges.lastWeek', 'Last Week')}
        </Translation>
      ),
      startDate: startOfWeek(subWeeks(baseDate, 1)),
      endDate: endOfWeek(subWeeks(baseDate, 1)),
      rangeType: 'lastWeek'
    },
    monthToDate: {
      label: (
        <Translation ns="common">
          {t => t('common:CalendarRanges.mtd', 'MTD')}
        </Translation>
      ),
      startDate: startOfMonth(baseDate),
      endDate: endOfToday,
      rangeType: 'monthToDate'
    },
    last30Days: {
      label: (
        <Translation ns="generic">
          {t => t('common:CalendarRanges.last30Days', 'Last 30 Days')}
        </Translation>
      ),
      startDate: startOfDay(subDays(baseDate, 29)),
      endDate: endOfToday,
      rangeType: 'last30Days'
    },
    lastMonth: {
      label: (
        <Translation ns="generic">
          {t => t('common:CalendarRanges.lastMonth', 'Last Month')}
        </Translation>
      ),
      startDate: startOfMonth(subMonths(baseDate, 1)),
      endDate: endOfMonth(subMonths(baseDate, 1)),
      rangeType: 'lastMonth'
    },
    quarterToDate: {
      label: (
        <Translation ns="common">
          {t => t('common:CalendarRanges.qtd', 'QTD')}
        </Translation>
      ),
      startDate: startOfMonth(subMonths(baseDate, currentMonthNumber % 3)),
      endDate: endOfToday,
      rangeType: 'quarterToDate'
    },
    lastQuarter: {
      label: (
        <Translation ns="generic">
          {t => t('common:CalendarRanges.lastQuarter', 'Last Quarter')}
        </Translation>
      ),
      startDate: startOfMonth(
        subMonths(baseDate, (currentMonthNumber % 3) + 3)
      ),
      endDate: endOfMonth(subMonths(baseDate, (currentMonthNumber % 3) + 1)),
      rangeType: 'lastQuarter'
    },
    threeMonths: {
      label: (
        <Translation ns="generic">
          {t =>
            t('common:CalendarRanges.months', '{{count}} Months', { count: 3 })
          }
        </Translation>
      ),
      startDate: startOfDay(subMonths(baseDate, 3)),
      endDate: endOfToday,
      rangeType: 'threeMonths'
    },
    sixMonths: {
      label: (
        <Translation ns="generic">
          {t =>
            t('common:CalendarRanges.months', '{{count}} Months', { count: 6 })
          }
        </Translation>
      ),
      startDate: startOfDay(subMonths(baseDate, 6)),
      endDate: endOfToday,
      rangeType: 'sixMonths'
    },
    yearToDate: {
      label: (
        <Translation ns="common">
          {t => t('common:CalendarRanges.ytd', 'YTD')}
        </Translation>
      ),
      startDate: startOfYear(new Date(baseDate.getFullYear(), 0, 1)),
      endDate: endOfToday,
      rangeType: 'yearToDate'
    },
    lastYear: {
      label: (
        <Translation ns="generic">
          {t => t('common:CalendarRanges.lastYear', 'Last Year')}
        </Translation>
      ),
      startDate: startOfYear(subYears(baseDate, 1)),
      endDate: endOfYear(subYears(baseDate, 1)),
      rangeType: 'lastYear'
    },
    last12Months: {
      label: (
        <Translation ns="generic">
          {t =>
            t('common:CalendarRanges.lastMonths', 'Last {{count}} Months', {
              count: 12
            })
          }
        </Translation>
      ),
      startDate: startOfDay(subMonths(baseDate, 12)),
      endDate: endOfToday,
      rangeType: 'last12Months'
    },
    lastTwoYears: {
      label: (
        <Translation ns="generic">
          {t => t('common:CalendarRanges.last2Years', '2 Years')}
        </Translation>
      ),
      startDate: startOfDay(subYears(baseDate, 2)),
      endDate: endOfToday,
      rangeType: 'lastTwoYears'
    },
    custom: {
      label: (
        <Translation ns="common">
          {t => t('common:CalendarRanges.customItem', 'Custom')}
        </Translation>
      ),
      startDate: new Date(startDate),
      endDate: new Date(baseDate),
      rangeType: 'custom'
    }
  }
}

export const getSelectedRange = (ranges, startDate, endDate, selectedRange) => {
  let selectedRangeResult

  if (selectedRange) {
    selectedRangeResult = ranges.find(
      range => range.rangeType === selectedRange
    )
  } else {
    selectedRangeResult = ranges.find(
      range =>
        isSameDay(range.startDate, startOfDay(startDate)) &&
        isSameDay(range.endDate, endOfDay(endDate || startDate))
    )
  }

  return (
    selectedRangeResult || calendarDateRangeOptions(endDate, startDate).custom
  )
}

// This can't go into the constant file because it needs to be created dynamically
// As a constant the today's date would always be the same when leaving the app open for several days
export const createBaseDateRangesV2 = (baseDate, startDate) => {
  const dateRangeOptions = calendarDateRangeOptions(baseDate, startDate)

  return [
    dateRangeOptions.today,
    dateRangeOptions.yesterday,
    dateRangeOptions.weekToDate,
    dateRangeOptions.lastWeek,
    dateRangeOptions.monthToDate,
    dateRangeOptions.last30Days,
    dateRangeOptions.lastMonth,
    dateRangeOptions.quarterToDate,
    dateRangeOptions.lastQuarter,
    dateRangeOptions.yearToDate,
    dateRangeOptions.lastYear,
    dateRangeOptions.last12Months
  ]
}

export const computePreviousPeriodRange = (
  startDate,
  endDate,
  comparisonType,
  dateRangeType
) => {
  if (!(startDate && endDate)) {
    return {}
  }

  if (comparisonType && comparisonType === COMPARISON_TYPES.previousYear) {
    return {
      comparedStartDate: startOfDay(subYears(startDate, 1)),
      comparedEndDate: startOfDay(subYears(endDate, 1))
    }
  }

  if (!dateRangeType) {
    return {}
  }

  switch (dateRangeType) {
    case 'today' || 'yesterday':
      return {
        comparedStartDate: startOfDay(subDays(startDate, 1)),
        comparedEndDate: endOfDay(subDays(endDate, 1))
      }
    case 'weekToDate' || 'lastWeek':
      return {
        comparedStartDate: startOfDay(subWeeks(startDate, 1)),
        comparedEndDate: endOfDay(subWeeks(endDate, 1))
      }
    case 'monthToDate' || 'lastMonth':
      return {
        comparedStartDate: startOfDay(subMonths(startDate, 1)),
        comparedEndDate: endOfDay(subMonths(endDate, 1))
      }
    case 'last30Days':
      return {
        comparedStartDate: startOfDay(subDays(startDate, 29)),
        comparedEndDate: endOfDay(subDays(endDate, 30))
      }
    case 'yearToDate' || 'lastYear':
      return {
        comparedStartDate: startOfDay(subYears(startDate, 1)),
        comparedEndDate: endOfDay(subYears(endDate, 1))
      }
    case 'quarterToDate' || 'lastQuarter':
      return {
        comparedStartDate: startOfDay(subMonths(startDate, 3)),
        comparedEndDate: endOfDay(subMonths(endDate, 3))
      }
    case 'last12Months':
      return {
        comparedStartDate: startOfDay(subMonths(startDate, 12)),
        comparedEndDate: endOfDay(subMonths(endDate, 12))
      }
    default: {
      // the difference between days is smaller by one than the actual number of days
      const rangeInDays = differenceInDays(endDate, startDate) + 1
      return {
        comparedStartDate: startOfDay(subDays(startDate, rangeInDays)),
        comparedEndDate: endOfDay(subDays(endDate, rangeInDays))
      }
    }
  }
}
